// Initialize and add countdown
export default class Countdown extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'countdown-container';


  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }
  // Initialize Function
  initialize() {
    super.initialize();
    this.componentShow();
    this.countdownValueHide = document.querySelector('.countdown-value');
  }

  /*
  Function to show the component
  */
  componentShow() {
    const countdownComp = document.getElementsByClassName('countdown-container');
    const endDate = countdownComp[0].dataset.bookingenddate && countdownComp[0].dataset.bookingenddate.split('/');
    const currentMonth = new Date().getMonth() + 1;
    const endDateFormat = endDate ? `${endDate[1]}/${endDate[0]}/${endDate[2]}` : null;
    if ((!countdownComp[0].dataset.bookingenddate && !countdownComp[0].dataset.offerCategory) ||
    (!countdownComp[0].dataset.offerCategory && +endDate[1] > currentMonth )) {
      const countdownCompHide = document.querySelector('.countdown-container');
      countdownCompHide.classList.add('componentHide');
    } else if ((countdownComp[0].dataset.offerCategory &&
      (!countdownComp[0].dataset.bookingenddate || +endDate[1] > currentMonth))) {
      const countdownLabelHide = document.querySelector('.countdown-label');
      const countdownValueHide = document.querySelector('.countdown-value');
      document.querySelector('.countdown-typeofoffer').innerHTML = countdownComp[0].dataset.offerCategory;
      countdownLabelHide.classList.add('labelHide');
      countdownValueHide.classList.add('valueHide');
      document.querySelector('.countdown-offercategory').style.padding = 'rem(2px) rem(8px) rem(0px) rem(8px)';
      countdownComp[0].classList.add('componentShow');
    } else {
      document.querySelector('.countdown-typeofoffer').innerHTML = countdownComp[0].dataset.offerCategory;
      this.countdownCounter(endDateFormat);
    }
  }

  /*
  Function to create a countdown counter.
  */
  countdownCounter(endDateFormat) {
    const countdownCompo = document.getElementsByClassName('countdown-container');
    const countdownOfferCat = document.querySelector('.countdown-offercategory');
    const countdownLabel = document.querySelector('.countdown-label');
    const countdownDayLabel = document.querySelector('#day');
    const countdownDaysLabel = document.querySelector('#days');
    const labelTextHide = document.querySelector('.countdown-labeltext');
    const labelTextEndedHide = document.querySelector('.countdown-labeltextended');
    const valueText = document.querySelector('.countdown-valuetext');
    const countdownCatText = document.querySelector('.countdown-categorytext');
    if ((countdownCompo[0].dataset.bookingenddate && !countdownCompo[0].dataset.offerCategory)) {
      countdownCatText.style.visibility = 'hidden';
    }


    const countDownDate = new Date(`${endDateFormat} 23:59:59`).getTime();
    const x = setInterval(() => {
      const currentDate = new Date().getTime();

      // Calculate time difference
      const distance = countDownDate - currentDate;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / (1000));


      document.getElementById('countdowndays').innerHTML = days.toString().length == 1 ? `0${days}` : days;
      document.getElementById('countdownhours').innerHTML = hours.toString().length == 1 ? `0${hours}` : hours;
      document.getElementById('countdownminutes').innerHTML = minutes.toString().length == 1 ? `0${minutes}` : minutes;
      document.getElementById('countdownseconds').innerHTML = seconds.toString().length == 1 ? `0${seconds}` : seconds;


      if (days <= '0') {
        const countdownDays = document.querySelector('.countdown-days');
        countdownDays.classList.add('daysHide');
      }

      if (days > 1) {
        countdownDayLabel.style.display = 'none';
      } else if (days == 1) {
        countdownDaysLabel.style.display = 'none';
        countdownDayLabel.style.display = 'block';
      }
      if (distance < 1000) {
        clearInterval(x);
        labelTextHide.classList.add('labelTextHide');
        labelTextEndedHide.classList.remove('labeltextendedHide');
        valueText.classList.add('valueTextHide');
      } else {
        labelTextEndedHide.classList.add('labeltextendedHide');
        labelTextHide.classList.remove('labelTextHide');
      }

      if ((countdownCompo[0].dataset.bookingenddate && !countdownCompo[0].dataset.offerCategory)) {
        countdownOfferCat.style.display = 'none';
        countdownLabel.style.padding = 'rem(2px) rem(9px) rem(0px) rem(8px)';
        countdownLabel.style.marginLeft = 'rem(2px)';
        document.querySelector('.countdown-categorytext').style.marginRight = 'rem(2px)';
      }
    }, 1000);
    countdownCompo[0].classList.add('componentShow');
  };
}
// Register the Countdown component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Countdown.CLASS_NAMESPACE, Countdown);
