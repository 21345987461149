/**
 * @author Rishabh
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */
export default class DatePicker extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-date-component';

  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost);
  }

  /** @inheritDoc */
  initialize() {
    super.initialize();
    this.checkInData = this.componentHost.querySelector('.ace-core-booking-engine__check-in');
    this.checkOutData = this.componentHost.querySelector('.ace-core-booking-engine__check-out');
    this.maxDate = 405;
    this.localization = this.componentHost
      .querySelector('[data-localization]')
      .getAttribute('data-localization');
    this.checkinDatePicker = this.componentHost.querySelector(
      "[data-date='checkin']"
    );
    this.checkoutDatePicker = this.componentHost.querySelector(
      "[data-date='checkout']"
    );
    this.language = document.documentElement.lang ?? 'en';

    let datePickerReady = 0;
    this.datePickerReady = false;
    this.pickers = this.componentHost.querySelectorAll('duet-date-picker');
    if (this.pickers) {
      const mutationConfig = {
        childList: true,
        subtree: true
      };
      this.pickers.forEach((picker) => {
        if (!picker.hasChildNodes()) {
          const observer = new MutationObserver(() => {
            datePickerReady++;
            observer.disconnect();
            if (datePickerReady == 2) {
              this.datePickerReady = true;
              this.configDatePicker();
              this.dateChangeHandler();
              this.bindBooknowEvents();
            }
          });
          observer.observe(picker, mutationConfig);
        } else {
          datePickerReady++;
          if (datePickerReady == 2) {
            this.datePickerReady = true;
            this.configDatePicker();
            this.dateChangeHandler();
            this.bindBooknowEvents();
          }
        }
      });
    };

    const localizationSpan = this.componentHost.querySelector('[data-localization]');
    localizationSpan.remove();
    window.addEventListener(CoreJS.DomEventConstants.LOAD, () => this.onLoadDateIn());
  }

  dateConfigInitial() {
    const todayDate = new Date();
    this.leadTime = this.checkInData.getAttribute('data-leadtime') ? this.checkInData.getAttribute('data-leadtime') : 0;
    if (this.checkInData.getAttribute('data-staying-start-date')) {
      const stayingStartdate = this.convertToDate(this.checkInData.getAttribute('data-staying-start-date'));
      if (stayingStartdate.getTime() > todayDate.getTime()) {
        this.minimumCheckInDate = this.addDays(stayingStartdate, +this.leadTime);
      } else {
        this.minimumCheckInDate = this.addDays(todayDate, +this.leadTime);
      }
    } else {
      this.minimumCheckInDate = this.addDays(todayDate, +this.leadTime);
    }
    this.minimumNightStay = this.checkInData.getAttribute('data-min-stay') ? this.checkInData.getAttribute('data-min-stay') : 1;
    this.maximumNightStay = this.checkInData.getAttribute('data-max-stay') ? this.checkInData.getAttribute('data-max-stay') : 30;
    this.maximumCheckInDate = this.addDays(todayDate, +this.maxDate); // today date + 405 days
    if (this.checkInData.getAttribute('data-staying-end-date')) {
      this.maximumCheckInDate = this.subtractDays(this.convertToDate(this.checkInData.getAttribute('data-staying-end-date')), +this.minimumNightStay);
    } else {
      this.maximumCheckInDate = this.subtractDays(this.maximumCheckInDate, +this.minimumNightStay);
    }


    // mincheckout date
    this.minimumCheckOutDate = this.addDays(this.minimumCheckInDate, +this.minimumNightStay);
    const minCheckoutDate = this.addDays(this.minimumCheckInDate, +this.minimumNightStay);
    if (minCheckoutDate.getTime() > this.minimumCheckInDate.getTime()) {
      this.minimumCheckOutDate = this.addDays(this.minimumCheckInDate, +this.minimumNightStay);
    }

    // maxcheckout date
    this.maximumCheckOutDate = this.addDays(todayDate, +this.maximumNightStay);
    const maxCheckoutDate = this.subtractDays(this.addDays(todayDate, +this.maxDate), +this.maximumNightStay);
    if (this.minimumCheckInDate.getTime() > maxCheckoutDate.getTime()) {
      this.maximumCheckOutdate = this.addDays(this.minimumCheckInDate, +this.maximumNightStay);
    } else {
      if (this.minimumCheckInDate.getTime() > todayDate.getTime()) {
        this.maximumCheckOutDate = this.addDays(this.minimumCheckInDate, +this.maximumNightStay);
      }
    }

    // min and max checkout
    this.currentCheckoutDate = this.addDays(todayDate, +this.minimumNightStay);
    if (this.checkInData.getAttribute('data-max-stay')) {
      this.currentCheckoutDate = this.addDays(this.minimumCheckInDate, +this.minimumNightStay);
    } else if (this.checkInData.getAttribute('data-min-stay')) {
      this.currentCheckoutDate = this.addDays(this.minimumCheckInDate, +this.minimumNightStay);
    } else if (this.minimumCheckInDate.getTime() > todayDate.getTime()) {
      if (minCheckoutDate.getTime() > this.minimumCheckInDate.getTime()) {
        this.currentCheckoutDate = this.addDays(this.minimumCheckInDate, +this.minimumNightStay);
      } else {
        this.currentCheckoutDate = this.addDays(this.minimumCheckInDate, +this.maximumNightStay);
      }
    }

    // staying end date

    if (this.checkInData.getAttribute('data-staying-end-date')) {
      const stayingEnddate = this.subtractDays(this.convertToDate(this.checkInData.getAttribute('data-staying-end-date')), +this.maximumNightStay);
      if (stayingEnddate.getTime() > this.minimumCheckInDate.getTime()) {
        this.maximumCheckOutdate = this.addDays(this.minimumCheckInDate, +this.maximumNightStay);
      } else {
        this.maximumCheckOutDate = this.addDays(this.convertToDate(this.checkInData.getAttribute('data-staying-end-date')), +this.leadTime);
      }
    }
  }

  datePickerFormat() {
    for (const picker of this.pickers) {
      picker.dateAdapter = {
        parse(value = '', createDate) {
          const matches = value.match(this.dateFormat);
          if (matches) {
            return createDate(matches[3], matches[2], matches[1]);
          }
        },
        format(date) {
          const day = date.getDate();
          const month = date.getMonth() + 1;
          // eslint-disable-next-line no-unused-vars
          const cookieZone = document.cookie.match(
            new RegExp('(^| )' +
                              'displayZone' +
                              '=([^;]+)')
          );
          let returnType;
          if (cookieZone && cookieZone[2] === CoreJS.CookieConstants.DISPLAY_ZONE_USA) {
            returnType = `${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day
            }/${date.getFullYear()}`;
          } else {
            returnType = `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month
            }/${date.getFullYear()}`;
          }
          return returnType;
        }
      };
      // Altering datepicker heading config part of defect ACDAA-18577
      if (this.componentHost?.closest('.core-booking-engine__homepagewrapper')) {
        this.localization = JSON.parse(this.localization);
        if (this.checkinDatePicker?.dataset.dateLabel && this.checkoutDatePicker?.dataset.dateLabel) {
          if (picker?.dataset.date === this.checkinDatePicker?.dataset.date) {
            this.localization.calendarHeading = this.checkinDatePicker?.dataset.dateLabel;
          } else if (picker?.dataset.date === this.checkoutDatePicker?.dataset.date) {
            this.localization.calendarHeading = this.checkoutDatePicker?.dataset.dateLabel;
          }
        }
        this.localization = JSON.stringify(this.localization);
      }
      picker.localization = JSON.parse(this.localization);
    }
    this.componentHost.querySelectorAll('.duet-date__table-header')?.forEach((element) => {
      element.querySelector('span:first-child').innerText = element.querySelector('.duet-date__vhidden')?.innerHTML.substring(0, 3).toLowerCase();
    });

    this.checkinDatePicker.previousElementSibling.setAttribute(
      'for',
      this.checkinDatePicker.getAttribute('identifier')
    );
    this.checkoutDatePicker.previousElementSibling.setAttribute(
      'for',
      this.checkoutDatePicker.getAttribute('identifier')
    );
  }

  configDatePicker() {
    if (this.datePickerReady && this.checkinDatePicker && this.checkoutDatePicker) {
      // set the language attribute for checkin and checkout

      this.checkinDatePicker.setAttribute('language', this.language);
      this.checkoutDatePicker.setAttribute('language', this.language);
      // set value to checkin and checkout
      this.checkinDatePicker.setAttribute('value', '');
      this.checkoutDatePicker.setAttribute('value', '');
      // set min attribute to checkin
      this.leadDate = this.checkInData.getAttribute('data-leadtime') ? this.checkInData.getAttribute('data-leadtime') : 0;
      this.currentDate = new Date();
      if (this.checkInData.getAttribute('data-staying-start-date')) {
        this.stayStrtDate = this.convertToDate(this.checkInData.getAttribute('data-staying-start-date'));
        if (this.stayStrtDate.getTime() > this.currentDate.getTime()) {
          this.minCheckIn = this.dateFormatConverter(this.addDays(this.stayStrtDate, + this.leadDate));
        } else {
          this.minCheckIn = this.dateFormatConverter(this.addDays(new Date(), + this.leadDate));
        }
      } else {
        this.minCheckIn = this.dateFormatConverter(this.addDays(new Date(), + this.leadDate));
      }
      this.checkinDatePicker.setAttribute('min', this.minCheckIn);
      this.minimumCheckInDate = this.checkinDatePicker.getAttribute('min');
      // set max attribute to checkin
      this.minNightStay = this.checkInData.getAttribute('data-min-stay') ? this.checkInData.getAttribute('data-min-stay') : 1;
      this.maxNightStay = this.checkInData.getAttribute('data-max-stay') ? this.checkInData.getAttribute('data-max-stay') : 30;
      if (this.checkInData.getAttribute('data-staying-end-date')) {
        this.stayEnDate = this.convertToDate(this.checkInData.getAttribute('data-staying-end-date'));
        this.maxCheckIn = this.dateFormatConverter(
          this.subtractDays(this.stayEnDate, +this.minNightStay
          ));
      } else {
        this.maxCheckIn = this.dateFormatConverter(
          this.subtractDays((this.addDays(new Date(), +this.maxDate)), +this.minNightStay)
        );
      }
      this.checkinDatePicker.setAttribute('max', this.maxCheckIn);
      // set min attribute to checkout
      this.minCheckOut = this.dateFormatConverter(
        this.addDays(new Date(`${this.minCheckIn}T12:00:00`), +this.minNightStay)
      );
      this.checkoutDatePicker.setAttribute('min', this.minCheckOut);
      this.minimumCheckOutDate = this.checkoutDatePicker.getAttribute('min');
      // set max attribute to checkout
      this.maxCheckOut = this.dateFormatConverter(
        this.addDays(new Date(`${this.minCheckIn}T12:00:00`), +this.maxNightStay)
      );
      this.checkoutDatePicker.setAttribute('max', this.maxCheckOut);

      if (this.minimumCheckInDate) {
        this.checkinDatePicker.querySelector('.duet-date__input')?.setAttribute('placeholder', this.convertToPlaceholder(this.minimumCheckInDate));
      }

      if (this.minimumCheckOutDate) {
        this.checkoutDatePicker.querySelector('.duet-date__input')?.setAttribute('placeholder', this.convertToPlaceholder(this.minimumCheckOutDate));
      }
    }
  }

  /**
       * Convert Date Long format to ISO-8601 and return the date formatted
       * @param {Date} parameterDate to be transformed to display in YYYY-MM-DD format
       * @return {Date} Date
       */
  dateFormatConverter(parameterDate) {
    if (parameterDate) {
      const year = parameterDate.getFullYear();
      let month = parameterDate.getMonth() + 1;
      let date = parameterDate.getDate();
      if (date < 10) {
        date = `0${date}`;
      }
      if (month < 10) {
        month = `0${month}`;
      }
      return `${year}-${month}-${date}`;
    }
  }

  /**
       * Add number of days to a Date (Long format) and returns the result Date
       * @param {Date} date
       * @param {Date} days
       * @return
       */

  addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }

  subtractDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() - days);
    return copy;
  }

  dateChangeHandler() {
    if (this.componentHost?.closest('.core-booking-engine__homepagewrapper')) {
      this.checkinDatePicker.querySelector('.duet-date__dialog').addEventListener('click', (event) => {
        event.stopPropagation();
      });

      this.checkoutDatePicker.querySelector('.duet-date__dialog').addEventListener('click', (event) => {
        event.stopPropagation();
      });
    }
    //  checkIn change handler
    this.checkinDatePicker.addEventListener('duetChange', () => {
      this.checkinDatePicker.removeAttribute('data-value-wrong');
      this.dateConfigInitial();
      const todayDate = new Date();
      if (this.checkinDatePicker.value) {
        this.checkoutDatePicker.removeAttribute('disabled');
        const maxCheckoutDate = this.subtractDays(this.addDays(todayDate, +this.maxDate), +this.maximumNightStay);
        const selectedCheckinDate = new Date(`${this.checkinDatePicker.value}T12:00:00`);
        let maxSelectedCheckOut;
        // Calculate Departure day (Arrival date + 30)
        if (!this.componentHost.classList.contains('ace-long-stay__content')) {
          maxSelectedCheckOut = this.addDays(new Date(`${this.checkinDatePicker.value}T12:00:00`), +this.maximumNightStay);
        }
        // Calculate Departure day (Arrival date + 450) for Long stay form
        if (this.componentHost.classList.contains('ace-long-stay__content')) {
          maxSelectedCheckOut = this.addDays(new Date(`${this.checkinDatePicker.value}T12:00:00`), +this.maxDate);
        }
        if (selectedCheckinDate.getTime() > maxCheckoutDate.getTime()) {
          maxSelectedCheckOut = this.addDays(todayDate, +this.maxDate);
        }

        if (this.checkInData.getAttribute('data-staying-end-date')) {
          const stayingEnddate = this.subtractDays(this.convertToDate(this.checkInData.getAttribute('data-staying-end-date')), +this.maximumNightStay);
          if (stayingEnddate.getTime() < selectedCheckinDate.getTime()) {
            maxSelectedCheckOut = this.convertToDate(this.checkInData.getAttribute('data-staying-end-date'));
          }
        }

        this.checkoutDatePicker.setAttribute(
          'min',
          this.dateFormatConverter(
            this.addDays(
              new Date(`${this.checkinDatePicker.value}T12:00:00`),
              +this.minimumNightStay
            )
          )
        );
        this.checkoutDatePicker.setAttribute(
          'value',
          this.dateFormatConverter(
            this.addDays(
              new Date(`${this.checkinDatePicker.value}T12:00:00`),
              +this.minimumNightStay
            )
          )
        );
        this.checkoutDatePicker.setAttribute(
          'max',
          this.dateFormatConverter(
            maxSelectedCheckOut
          )
        );
        this.checkinDatePicker.disabled = false;

        this.checkinDatePicker.hide();
        if ((window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg)) {
          this.checkoutDatePicker.show();

          setTimeout(() => {
            this.checkoutDatePicker
              .querySelector('.duet-date__select--month')
              .focus();
            this.checkoutDatePicker.querySelector('.duet-date__input').classList.add('active');
          }, 600);
        }
      } else {
        this.checkoutDatePicker.value = '';
        this.checkoutDatePicker.setAttribute('disabled', 'disabled');
      }

      this.inputHtmlData();
    });

    this.checkoutDatePicker.addEventListener('duetChange', () => {
      if (this.componentHost.classList.contains('ace-long-stay__content')) {
        this.checkoutDatePicker.removeAttribute('data-value-wrong');
      }
      if (this.componentHost?.closest('.core-booking-engine__homepagewrapper')) {
        this.checkoutDatePicker.querySelector('.duet-date__input').blur();
      }
      this.checkoutDatePicker.hide();

      this.inputHtmlData();
    });
  }

  /**
       *
       * @return {Date} Day Number of days diffrence betweeen checkin & checkout dates
       */
  getDaysDiffrence() {
    return Math.ceil(
      Math.abs(
        new Date(this.checkoutDatePicker.value) -
                  new Date(this.checkinDatePicker.value)
      ) /
              (1000 * 60 * 60 * 24)
    );
  }

  bindBooknowEvents() {
    this.checkinDatePicker?.addEventListener(CoreJS.DomEventConstants.KEY_UP, (event) => {
      if (
        event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.left &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.right &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.delete &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.shift &&
                  event.target.value.length === 10
      ) {
        this.checkinDatePicker.setAttribute('data-value', event.target.value);
        this.checkinDatePicker.removeAttribute('data-value-wrong');

        const dateString = this.checkinDatePicker.getAttribute('data-value');
        let day;
        let month;
        let year;
        // eslint-disable-next-line no-undef
        const cookieZone = document.cookie.match(
          new RegExp('(^| )' +
                          'displayZone' +
                          '=([^;]+)')
        );
        if (cookieZone && cookieZone[2] === CoreJS.CookieConstants.DISPLAY_ZONE_USA) {
          [month, day, year] = dateString.split('/');
        } else {
          [day, month, year] = dateString.split('/');
        }

        //  Create valid Date object
        const date = new Date(Date.UTC(+year, month - 1, +day));
        if (
          date >= new Date(this.checkinDatePicker.min) &&
                      date <= new Date(this.checkinDatePicker.max)
        ) {
          this.checkinDatePicker.setAttribute(
            'value',
            this.dateFormatConverter(date)
          );

          this.checkoutDatePicker.setAttribute(
            'value',
            this.dateFormatConverter(this.addDays(date, 1))
          );

          this.checkoutDatePicker.setAttribute(
            'min',
            this.dateFormatConverter(
              this.addDays(new Date(date), +this.minimumNightStay)
            )
          );
          this.checkoutDatePicker.setAttribute(
            'max',
            this.dateFormatConverter(
              this.addDays(new Date(date), +this.maximumNightStay)
            )
          );

          this.checkinDatePicker.show();
          this.inputHtmlData();
        } else {
          this.checkinDatePicker.setAttribute('data-value-wrong', event.target.value);
          this.inputHtmlData('checkIn');
        }
      } else if (event.target.value.length === 0) {
        this.checkinDatePicker.removeAttribute('data-value-wrong');
        this.componentHost.querySelectorAll(
          '.core-booking-engine__date-search input'
        )?.forEach((input) => {
          input.value = 'default';
        });
      } else if (event.target.value.length !== 10 &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.delete &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.shift && event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.enter) {
        this.checkinDatePicker.setAttribute('data-value-wrong', event.target.value);
        this.inputHtmlData('checkIn');
      }
    });

    if (!this.componentHost.classList.contains('ace-long-stay__content')) {
      this.checkoutDatePicker?.addEventListener(CoreJS.DomEventConstants.KEY_UP, (event) => {
        if (
          event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.left &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.right &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.delete &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.shift &&
                  event.target.value.length === 10
        ) {
          this.checkoutDatePicker.setAttribute('data-value', event.target.value);
          const dateString = this.checkoutDatePicker.getAttribute('data-value');
          let day;
          let month;
          let year;
          // eslint-disable-next-line no-undef
          const cookieZone = document.cookie.match(
            new RegExp('(^| )' +
                          'displayZone' +
                          '=([^;]+)')
          );
          if (cookieZone && cookieZone[2] === CoreJS.CookieConstants.DISPLAY_ZONE_USA) {
            [month, day, year] = dateString.split('/');
          } else {
            [day, month, year] = dateString.split('/');
          }

          // Create valid Date object
          const date = new Date(Date.UTC(+year, month - 1, +day));
          if (
            date >= new Date(this.checkoutDatePicker.min) &&
                      date <= new Date(this.checkoutDatePicker.max)
          ) {
            this.checkoutDatePicker.setAttribute(
              'value',
              this.dateFormatConverter(date)
            );
            this.checkoutDatePicker.show();
            this.inputHtmlData();
          } else if (day > 32 || day == 0 || month == 0 || month > 12 || year.length !== 4) {
            this.inputHtmlData('checkOut');
          } else {
            this.inputHtmlData('checkOut');
          }
        } else if (event.target.value.length === 0) {
          const checkout = this.componentHost.querySelector(
            '.core-booking-engine__date-search input[name="search.nightNb"]'
          );
          checkout.value = 'default';
        } else if (event.target.value.length !== 10 &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.delete &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.shift) {
          this.inputHtmlData('checkOut');
        }
      });
    }

    // Long Stay Form departure error field.
    if (this.componentHost.classList.contains('ace-long-stay__content')) {
      this.checkoutDatePicker?.addEventListener(CoreJS.DomEventConstants.KEY_UP, (event) => {
        if (
          event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.left &&
              event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.right &&
              event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.delete &&
              event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab &&
              event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.shift &&
              event.target.value.length === 10
        ) {
          this.checkoutDatePicker.setAttribute('data-value', event.target.value);
          const dateString = this.checkoutDatePicker.getAttribute('data-value');
          let day; let month; let year;
          const cookieZone = document.cookie.match(
            new RegExp('(^| )' +
                      'displayZone' +
                      '=([^;]+)')
          );
          if (cookieZone && cookieZone[2] === CoreJS.CookieConstants.DISPLAY_ZONE_USA) {
            [month, day, year] = dateString.split('/');
          } else {
            [day, month, year] = dateString.split('/');
          }
          //  Create valid Date object
          const date = new Date(Date.UTC(+year, month - 1, +day));
          if (
            date >= new Date(this.checkoutDatePicker.min) &&
                  date <= new Date(this.checkoutDatePicker.max)
          ) {
            this.checkoutDatePicker.setAttribute(
              'value',
              this.dateFormatConverter(this.addDays(date, 1))
            );
            this.checkoutDatePicker.setAttribute(
              'min',
              this.dateFormatConverter(
                this.addDays(new Date(date), +this.minimumNightStay)
              )
            );
            this.checkoutDatePicker.setAttribute(
              'max',
              this.dateFormatConverter(
                this.addDays(new Date(date), +this.maximumNightStay)
              )
            );
            this.checkoutDatePicker.show();
            this.inputHtmlData();
          } else {
            this.checkoutDatePicker.setAttribute('data-value-wrong', event.target.value);
            this.inputHtmlData('checkOut');
          }
        } else if (event.target.value.length === 0) {
          this.checkoutDatePicker.removeAttribute('data-value-wrong');
          this.componentHost.querySelectorAll(
            '.core-booking-engine__date-search input'
          )?.forEach((input)=>{
            input.value = 'default';
          });
        } else if (event.target.value.length !== 10 &&
              event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.delete &&
              event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab &&
              event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.shift && event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.enter) {
          this.checkoutDatePicker.setAttribute('data-value-wrong', event.target.value);
          this.inputHtmlData('checkIn');
        }
      });
    }

    this.checkOutDatePicker?.addEventListener(CoreJS.Constants.KEY_CODES, (event) => {
      if (event.shiftKey && event.keyCode === CoreJS.Constants.KEY_CODES.tab) {
        this.checkoutDatePicker.hide();
        this.checkinDatePicker.show();
      }
    });

    this.dateChangeHandler();
    this.datePickerFormat();
    this.configDatePicker();
    this.componentHost.querySelectorAll(
      '.core-booking-engine__date-search input'
    )?.forEach((input) => {
      input.value = 'default';
    });
    const element = this.componentHost.querySelectorAll('.duet-date__dialog');
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'aria-hidden') {
          this.componentHost
            .querySelectorAll('.duet-date__dialog')
            .forEach((item) => {
              item.removeAttribute('aria-hidden');
            });
        }
      });
    });
    element.forEach((item) => {
      observer.observe(item, {
        attributes: true
      });
    });
    window.addEventListener(CoreJS.DomEventConstants.CLICK, (clickEvent) => this.windowClickCloseDate(clickEvent));

    if (this.componentHost?.closest('.core-booking-engine__homepagewrapper')) {
      this.checkInData.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.checkinDatePicker.show();
      });

      this.checkOutData.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.checkoutDatePicker.show();
      });
    }

    this.checkinDatePicker.addEventListener('duetOpen', () => {
      this.checkinDatePicker.querySelector('.duet-date__input').classList.add('active');
      this.checkoutDatePicker.querySelector('.duet-date__input').classList.remove('active');
      this.checkoutDatePicker.removeAttribute('disabled');
    });
    this.checkinDatePicker.addEventListener('duetClose', () => {
      this.checkinDatePicker.querySelector('.duet-date__input').classList.remove('active');
      this.checkoutDatePicker.querySelector('.duet-date__input').classList.remove('active');
      this.lostFocus(this.checkinDatePicker.querySelector('.duet-date__input'));
    });
    this.checkoutDatePicker.addEventListener('duetOpen', () => {
      this.checkoutDatePicker.querySelector('.duet-date__input').classList.add('active');
      this.checkinDatePicker.querySelector('.duet-date__input').classList.remove('active');
    });
    this.checkoutDatePicker.addEventListener('duetClose', () => {
      this.checkinDatePicker.querySelector('.duet-date__input').classList.remove('active');
      this.checkoutDatePicker.querySelector('.duet-date__input').classList.remove('active');
      this.lostFocus(this.checkoutDatePicker.querySelector('.duet-date__input'));
    });
  }

  lostFocus(input) {
    if (!this.timeoutInput) {
      clearTimeout(this.timeoutInput);
    }

    this.timeoutInput = setTimeout(() => {
      input.focus();
      input.blur();
      this.timeoutInput = null;
    }, 510);
  }

  windowClickCloseDate(event) {
    if (!event.target.closest('.duet-date__input-wrapper')) {
      this.checkoutDatePicker.querySelector('.duet-date__input').classList.remove('active');
      this.checkinDatePicker.querySelector('.duet-date__input').classList.remove('active');
    }
  }

  inputHtmlData(data) {
    const date = new Date(`${this.checkinDatePicker.value}T12:00:00`);
    let dayIn = date.getDate();
    let monthIn = date.getMonth() + 1;
    let yearIn = date.getFullYear();
    let nightNB = this.getDaysDiffrence();
    if (data === 'checkIn' || this.checkinDatePicker.getAttribute('data-value-wrong')) {
      dayIn = '';
      monthIn = '';
      yearIn = '';
      nightNB = '';
    } else if (data === 'checkOut') {
      nightNB = '';
      if (this.checkinDatePicker.getAttribute('data-value-wrong')) {
        dayIn = '';
        monthIn = '';
        yearIn = '';
      }
    }
    if (!this.componentHost.classList.contains('ace-long-stay__content')) {
      this.componentHost.querySelector('[name="search.dayIn"]').value = dayIn;
      this.componentHost.querySelector('[name="search.monthIn"]').value = monthIn;
      this.componentHost.querySelector('[name="search.yearIn"]').value = yearIn;
      this.componentHost.querySelector('[name="search.nightNb"]').value = nightNB;
    }
  }

  convertToDate(date) {
    const [day, month, year] = date.split('/');
    const newDate = new Date(+year, month - 1, +day);
    return newDate;
  }

  convertToPlaceholder(date) {
    const [year, month, day] = date.split('-');
    const cookieZone = document.cookie.match(
      new RegExp('(^| )' +
                  'displayZone' +
                  '=([^;]+)')
    );
    let returnType;
    if (cookieZone && cookieZone[2] === CoreJS.CookieConstants.DISPLAY_ZONE_USA) {
      returnType = `${month}/${day}/${year}`;
    } else {
      returnType = `${day}/${month}/${year}`;
    }
    return returnType;
  }

  onLoadDateIn() {
    const stayingDate = this.checkInData?.getAttribute('data-staying-start-date');
    const minStay = this.checkInData?.getAttribute('data-min-stay');
    const leadTime = this.checkInData?.getAttribute('data-leadtime');
    if (stayingDate || minStay || leadTime) {
      const checkInDate = this.checkinDatePicker?.querySelector('.duet-date__input')?.getAttribute('placeholder');
      const [day, month, year] = checkInDate.split('/');
      const minimumNightStay = this.checkInData.getAttribute('data-min-stay') ? this.checkInData.getAttribute('data-min-stay') : 1;
      if (!this.componentHost.classList.contains('ace-long-stay__content') && !this.componentHost?.closest('.core-booking-engine__homepagewrapper')) {
        this.componentHost.querySelector('[name="search.dayIn"]').value = day;
        this.componentHost.querySelector('[name="search.monthIn"]').value = month;
        this.componentHost.querySelector('[name="search.yearIn"]').value = year;
        this.componentHost.querySelector('[name="search.nightNb"]').value = minimumNightStay;
      }
    }
  }
}

CoreJS.BaseComponent.registerComponent(DatePicker.CLASS_NAMESPACE, DatePicker);
